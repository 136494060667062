/** @module application */

import { eventListenerOptions } from '@spinnwerk/polyfills';
import { liveEvents } from '../../../../assets/js/application';

/**
 * Manages clickability of card components.
 * Clickable card components are identified by class `.card` and attribute `data-clickable`.
 * @see https://inclusive-components.design/cards/
 * @returns {Promise<void>}
 */
export async function cards() {
    const selector = '.card[data-clickable]',
        ignoreClicksTo = '[data-no-click-target]',
        listenerOptions = eventListenerOptions({ passive: true });

    let down,
        downTarget,
        downCoords,
        up,
        upTarget,
        upCoords,
        moveThreshold = 10;

    (await liveEvents())
        .addEventListener(
            selector,
            'mousedown',
            (event) => {
                let swiper = event.target.closest('.cards--swiper');

                down = performance.now();
                downTarget = swiper ? swiper : event.target.closest(selector);
                downCoords = {
                    x: event.clientX,
                    y: event.clientY,
                };
            },
            listenerOptions,
        )
        .addEventListener(
            selector,
            'mouseup',
            (event) => {
                let swiper = event.target.closest('.cards--swiper');

                if (event.target.matches(ignoreClicksTo) || event.target.closest(ignoreClicksTo)) {
                    return;
                }

                up = performance.now();
                upTarget = swiper ? swiper : event.target.closest(selector);
                upCoords = {
                    x: event.clientX,
                    y: event.clientY,
                };

                const clickableCard = upTarget,
                    link = clickableCard && clickableCard.querySelector('.js-card-link');

                if (
                    up - down < 200 &&
                    downTarget === upTarget &&
                    (downTarget.matches('a') === false || downTarget.matches('.js-card-link')) &&
                    Math.abs(downCoords.x - upCoords.x) <= moveThreshold &&
                    Math.abs(downCoords.y - upCoords.y) <= moveThreshold &&
                    Boolean(clickableCard) &&
                    Boolean(link) &&
                    upTarget !== link
                ) {
                    if (event.button === 0) {
                        link.click();
                    } else if (event.button === 1) {
                        // emulate middle mouse button opening a new tab/window
                        let target = link.target,
                            hasNoReferrer = link.relList.contains('noreferrer');

                        link.target = '_blank';

                        if (!hasNoReferrer) {
                            link.relList.add('noreferrer');
                        }

                        link.click();

                        if (target) {
                            link.target = target;
                        } else {
                            link.removeAttribute('target');
                        }

                        if (!hasNoReferrer) {
                            link.relList.remove('noreferrer');
                        }
                    }
                }
            },
            listenerOptions,
        );
}
